export const MyTheme = {
  dark: true,
  colors: {
    primary: 'rgb(127,109,243)',
    background: 'rgb(255, 255, 255)',
    card: 'rgb(0, 0, 0)',
    background: 'rgb(242, 242, 242)',
    text: 'rgb(242, 242, 242)',
    border: 'rgb(199, 199, 204)',
    notification: 'rgb(255, 69, 58)',
  },
};

export const mapStyling = [{
    "elementType": "geometry",
    "stylers": [{
      "color": "#ebe3cd"
    }]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#523735"
    }]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [{
      "color": "#f5f1e6"
    }]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [{
      "color": "#c9b2a6"
    }]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "geometry.stroke",
    "stylers": [{
      "color": "#dcd2be"
    }]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#ae9e90"
    }]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [{
      "color": "#dfd2ae"
    }]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{
      "color": "#dfd2ae"
    }]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#93817c"
    }]
  },
  {
    "featureType": "poi.business",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#a5b076"
    }]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#447530"
    }]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{
      "color": "#f5f1e6"
    }]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [{
      "color": "#fdfcf8"
    }]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [{
      "color": "#f8c967"
    }]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [{
      "color": "#e9bc62"
    }]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [{
      "color": "#e98d58"
    }]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [{
      "color": "#db8555"
    }]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#806b63"
    }]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [{
      "color": "#dfd2ae"
    }]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#8f7d77"
    }]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.stroke",
    "stylers": [{
      "color": "#ebe3cd"
    }]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [{
      "color": "#dfd2ae"
    }]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [{
      "color": "#b9d3c2"
    }]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#92998d"
    }]
  }
]

export const breedList = [{
    "id": 9999,
    "name": "Mixed"
  },
  {
    "id": 9998,
    "name": "Unknown"
  },
  {
    "id": 1,
    "name": "Affenpinscher"
  },
  {
    "id": 2,
    "name": "Afghan Hound"
  },
  {
    "id": 3,
    "name": "African Hunting Dog"
  },
  {
    "id": 4,
    "name": "Airedale Terrier"
  },
  {
    "id": 5,
    "name": "Akbash Dog"
  },
  {
    "id": 6,
    "name": "Akita"
  },
  {
    "id": 7,
    "name": "Alapaha Blue Blood Bulldog"
  },
  {
    "id": 8,
    "name": "Alaskan Husky"
  },
  {
    "id": 9,
    "name": "Alaskan Malamute"
  },
  {
    "id": 10,
    "name": "American Bulldog"
  },
  {
    "id": 11,
    "name": "American Bully"
  },
  {
    "id": 12,
    "name": "American Eskimo Dog"
  },
  {
    "id": 13,
    "name": "American Eskimo Dog (Miniature)"
  },
  {
    "id": 14,
    "name": "American Foxhound"
  },
  {
    "id": 15,
    "name": "American Pit Bull Terrier"
  },
  {
    "id": 16,
    "name": "American Staffordshire Terrier"
  },
  {
    "id": 17,
    "name": "American Water Spaniel"
  },
  {
    "id": 18,
    "name": "Anatolian Shepherd Dog"
  },
  {
    "id": 19,
    "name": "Appenzeller Sennenhund"
  },
  {
    "id": 21,
    "name": "Australian Cattle Dog"
  },
  {
    "id": 22,
    "name": "Australian Kelpie"
  },
  {
    "id": 23,
    "name": "Australian Shepherd"
  },
  {
    "id": 24,
    "name": "Australian Terrier"
  },
  {
    "id": 25,
    "name": "Azawakh"
  },
  {
    "id": 26,
    "name": "Barbet"
  },
  {
    "id": 28,
    "name": "Basenji"
  },
  {
    "id": 29,
    "name": "Basset Bleu de Gascogne"
  },
  {
    "id": 30,
    "name": "Basset Hound"
  },
  {
    "id": 31,
    "name": "Beagle"
  },
  {
    "id": 32,
    "name": "Bearded Collie"
  },
  {
    "id": 33,
    "name": "Beauceron"
  },
  {
    "id": 34,
    "name": "Bedlington Terrier"
  },
  {
    "id": 36,
    "name": "Belgian Malinois"
  },
  {
    "id": 38,
    "name": "Belgian Tervuren"
  },
  {
    "id": 41,
    "name": "Bernese Mountain Dog"
  },
  {
    "id": 42,
    "name": "Bichon Frise"
  },
  {
    "id": 43,
    "name": "Black and Tan Coonhound"
  },
  {
    "id": 45,
    "name": "Bloodhound"
  },
  {
    "id": 47,
    "name": "Bluetick Coonhound"
  },
  {
    "id": 48,
    "name": "Boerboel"
  },
  {
    "id": 50,
    "name": "Border Collie"
  },
  {
    "id": 51,
    "name": "Border Terrier"
  },
  {
    "id": 53,
    "name": "Boston Terrier"
  },
  {
    "id": 54,
    "name": "Bouvier des Flandres"
  },
  {
    "id": 55,
    "name": "Boxer"
  },
  {
    "id": 56,
    "name": "Boykin Spaniel"
  },
  {
    "id": 57,
    "name": "Bracco Italiano"
  },
  {
    "id": 58,
    "name": "Briard"
  },
  {
    "id": 59,
    "name": "Brittany"
  },
  {
    "id": 61,
    "name": "Bull Terrier"
  },
  {
    "id": 62,
    "name": "Bull Terrier (Miniature)"
  },
  {
    "id": 64,
    "name": "Bullmastiff"
  },
  {
    "id": 65,
    "name": "Cairn Terrier"
  },
  {
    "id": 67,
    "name": "Cane Corso"
  },
  {
    "id": 68,
    "name": "Cardigan Welsh Corgi"
  },
  {
    "id": 69,
    "name": "Catahoula Leopard Dog"
  },
  {
    "id": 70,
    "name": "Caucasian Shepherd (Ovcharka)"
  },
  {
    "id": 71,
    "name": "Cavalier King Charles Spaniel"
  },
  {
    "id": 76,
    "name": "Chesapeake Bay Retriever"
  },
  {
    "id": 78,
    "name": "Chinese Crested"
  },
  {
    "id": 79,
    "name": "Chinese Shar-Pei"
  },
  {
    "id": 80,
    "name": "Chinook"
  },
  {
    "id": 81,
    "name": "Chow Chow"
  },
  {
    "id": 84,
    "name": "Clumber Spaniel"
  },
  {
    "id": 86,
    "name": "Cocker Spaniel"
  },
  {
    "id": 87,
    "name": "Cocker Spaniel (American)"
  },
  {
    "id": 89,
    "name": "Coton de Tulear"
  },
  {
    "id": 92,
    "name": "Dalmatian"
  },
  {
    "id": 94,
    "name": "Doberman Pinscher"
  },
  {
    "id": 95,
    "name": "Dogo Argentino"
  },
  {
    "id": 98,
    "name": "Dutch Shepherd"
  },
  {
    "id": 101,
    "name": "English Setter"
  },
  {
    "id": 102,
    "name": "English Shepherd"
  },
  {
    "id": 103,
    "name": "English Springer Spaniel"
  },
  {
    "id": 104,
    "name": "English Toy Spaniel"
  },
  {
    "id": 105,
    "name": "English Toy Terrier"
  },
  {
    "id": 107,
    "name": "Eurasier"
  },
  {
    "id": 108,
    "name": "Field Spaniel"
  },
  {
    "id": 110,
    "name": "Finnish Lapphund"
  },
  {
    "id": 111,
    "name": "Finnish Spitz"
  },
  {
    "id": 113,
    "name": "French Bulldog"
  },
  {
    "id": 114,
    "name": "German Pinscher"
  },
  {
    "id": 115,
    "name": "German Shepherd Dog"
  },
  {
    "id": 116,
    "name": "German Shorthaired Pointer"
  },
  {
    "id": 119,
    "name": "Giant Schnauzer"
  },
  {
    "id": 120,
    "name": "Glen of Imaal Terrier"
  },
  {
    "id": 121,
    "name": "Golden Retriever"
  },
  {
    "id": 123,
    "name": "Gordon Setter"
  },
  {
    "id": 124,
    "name": "Great Dane"
  },
  {
    "id": 125,
    "name": "Great Pyrenees"
  },
  {
    "id": 127,
    "name": "Greyhound"
  },
  {
    "id": 128,
    "name": "Griffon Bruxellois"
  },
  {
    "id": 129,
    "name": "Harrier"
  },
  {
    "id": 130,
    "name": "Havanese"
  },
  {
    "id": 134,
    "name": "Irish Setter"
  },
  {
    "id": 135,
    "name": "Irish Terrier"
  },
  {
    "id": 137,
    "name": "Irish Wolfhound"
  },
  {
    "id": 138,
    "name": "Italian Greyhound"
  },
  {
    "id": 140,
    "name": "Japanese Chin"
  },
  {
    "id": 141,
    "name": "Japanese Spitz"
  },
  {
    "id": 142,
    "name": "Keeshond"
  },
  {
    "id": 144,
    "name": "Komondor"
  },
  {
    "id": 145,
    "name": "Kooikerhondje"
  },
  {
    "id": 147,
    "name": "Kuvasz"
  },
  {
    "id": 149,
    "name": "Labrador Retriever"
  },
  {
    "id": 151,
    "name": "Lagotto Romagnolo"
  },
  {
    "id": 153,
    "name": "Lancashire Heeler"
  },
  {
    "id": 155,
    "name": "Leonberger"
  },
  {
    "id": 156,
    "name": "Lhasa Apso"
  },
  {
    "id": 161,
    "name": "Maltese"
  },
  {
    "id": 165,
    "name": "Miniature American Shepherd"
  },
  {
    "id": 167,
    "name": "Miniature Pinscher"
  },
  {
    "id": 168,
    "name": "Miniature Schnauzer"
  },
  {
    "id": 171,
    "name": "Newfoundland"
  },
  {
    "id": 172,
    "name": "Norfolk Terrier"
  },
  {
    "id": 176,
    "name": "Norwich Terrier"
  },
  {
    "id": 177,
    "name": "Nova Scotia Duck Tolling Retriever"
  },
  {
    "id": 178,
    "name": "Old English Sheepdog"
  },
  {
    "id": 179,
    "name": "Olde English Bulldogge"
  },
  {
    "id": 181,
    "name": "Papillon"
  },
  {
    "id": 183,
    "name": "Pekingese"
  },
  {
    "id": 184,
    "name": "Pembroke Welsh Corgi"
  },
  {
    "id": 185,
    "name": "Perro de Presa Canario"
  },
  {
    "id": 188,
    "name": "Pharaoh Hound"
  },
  {
    "id": 189,
    "name": "Plott"
  },
  {
    "id": 193,
    "name": "Pomeranian"
  },
  {
    "id": 196,
    "name": "Poodle"
  },
  {
    "id": 201,
    "name": "Pug"
  },
  {
    "id": 204,
    "name": "Puli"
  },
  {
    "id": 205,
    "name": "Pumi"
  },
  {
    "id": 207,
    "name": "Rat Terrier"
  },
  {
    "id": 208,
    "name": "Redbone Coonhound"
  },
  {
    "id": 209,
    "name": "Rhodesian Ridgeback"
  },
  {
    "id": 210,
    "name": "Rottweiler"
  },
  {
    "id": 211,
    "name": "Russian Toy"
  },
  {
    "id": 212,
    "name": "Saint Bernard"
  },
  {
    "id": 213,
    "name": "Saluki"
  },
  {
    "id": 214,
    "name": "Samoyed"
  },
  {
    "id": 216,
    "name": "Schipperke"
  },
  {
    "id": 218,
    "name": "Scottish Deerhound"
  },
  {
    "id": 219,
    "name": "Scottish Terrier"
  },
  {
    "id": 221,
    "name": "Shetland Sheepdog"
  },
  {
    "id": 222,
    "name": "Shiba Inu"
  },
  {
    "id": 223,
    "name": "Shih Tzu"
  },
  {
    "id": 225,
    "name": "Shiloh Shepherd"
  },
  {
    "id": 226,
    "name": "Siberian Husky"
  },
  {
    "id": 228,
    "name": "Silky Terrier"
  },
  {
    "id": 232,
    "name": "Smooth Fox Terrier"
  },
  {
    "id": 233,
    "name": "Soft Coated Wheaten Terrier"
  },
  {
    "id": 235,
    "name": "Spanish Water Dog"
  },
  {
    "id": 236,
    "name": "Spinone Italiano"
  },
  {
    "id": 238,
    "name": "Staffordshire Bull Terrier"
  },
  {
    "id": 239,
    "name": "Standard Schnauzer"
  },
  {
    "id": 242,
    "name": "Swedish Vallhund"
  },
  {
    "id": 243,
    "name": "Thai Ridgeback"
  },
  {
    "id": 244,
    "name": "Tibetan Mastiff"
  },
  {
    "id": 245,
    "name": "Tibetan Spaniel"
  },
  {
    "id": 246,
    "name": "Tibetan Terrier"
  },
  {
    "id": 248,
    "name": "Toy Fox Terrier"
  },
  {
    "id": 250,
    "name": "Treeing Walker Coonhound"
  },
  {
    "id": 251,
    "name": "Vizsla"
  },
  {
    "id": 253,
    "name": "Weimaraner"
  },
  {
    "id": 254,
    "name": "Welsh Springer Spaniel"
  },
  {
    "id": 256,
    "name": "West Highland White Terrier"
  },
  {
    "id": 257,
    "name": "Whippet"
  },
  {
    "id": 258,
    "name": "White Shepherd"
  },
  {
    "id": 259,
    "name": "Wire Fox Terrier"
  },
  {
    "id": 260,
    "name": "Wirehaired Pointing Griffon"
  },
  {
    "id": 261,
    "name": "Wirehaired Vizsla"
  },
  {
    "id": 262,
    "name": "Xoloitzcuintli"
  },
  {
    "id": 264,
    "name": "Yorkshire Terrier"
  }
];

export const vapidKey = 'BAHpTyISrcbLBNyYEn6QAXPAIKUkOh6GS_m80aZT7Sud5XumjRjBZ6sQFyYAc4JtZ3NQjen1r-ZdVZ2s39LjILU'


const keystore = {
  storePass: 'e67c8cb7fa66485a871ad6491c53c87e',
  keyAlias: 'QDJzaHJuYW5kL2RvZy13YXRjaA==',
  keyPass: '6220d564f3ac4a14acd39a47657826eb'
}

const googleOAuth = '967841760251-il35f1il76mb1s9r0843407dhdpu39fk.apps.googleusercontent.com'

export const googleAPI = "AIzaSyAUNeQ7Jio5NARRnUc_VCi_jPuLf-XXAco"

export const mapQuestKey = "WG8LKnZDaCcAa222dnNG3j0uJ5jRo5yW"


export const sentryKey = 'https://c5fd53eb5fba4b85b43bf197cf1aae35@o1122771.ingest.sentry.io/6160239';
export const sentryUserToken = '582b4eb577c24dc28355811d6e32590762cb51fd85d442a58439f42fc00f89f8';


export const firebaseConfig = {
  apiKey: "AIzaSyAKQpH5L_D3yHJ_65QXrNhQX4kCN7SIRME",
  authDomain: "dog-watch-13a9e.firebaseapp.com",
  projectId: "dog-watch-13a9e",
  storageBucket: "dog-watch-13a9e.appspot.com",
  messagingSenderId: "967841760251",
  appId: "1:967841760251:web:c0049d560200896706415e",
  measurementId: "G-15HNKQ7PR8"
};
